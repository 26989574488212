.root {
  --color: theme('colors.beige.light');
  --background-color: theme('colors.gray.500');
  --hover-color: theme('colors.gray.500');
  --shadow-color: rgba(51, 51, 51, 0.2);

  &--yellow {
    --color: theme('colors.beige.light');
    --background-color: theme('colors.yellow.base');
    --hover-color: theme('colors.yellow.highlight');
    --shadow-color: rgba(234, 177, 24, 0.3);
  }

  &--white-gray {
    --color: theme('colors.gray.500');
    --background-color: theme('colors.white');
    --hover-color: theme('colors.white');
    --shadow-color: transparent;
  }

  &--white-yellow {
    --color: theme('colors.yellow.base');
    --background-color: theme('colors.white');
    --hover-color: theme('colors.yellow.highlight');
    --shadow-color: transparent;
  }
}

.root {
  padding: 0.625rem 1.5rem;
  display: inline-block;
  border-radius: 9999px;
  color: var(--color);
  background: var(--background-color);
  font-family: theme('fontFamily.grotesk');
  font-weight: theme('fontWeight.semibold');
  font-size: theme('fontSize.sm');
  letter-spacing: theme('letterSpacing.widest');
  text-transform: uppercase;
  border: 1px solid transparent;
  transition: all 0.25s ease;

  @screen sm {
    padding: 0.625rem 2rem;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    box-shadow: 0 8px 40px var(--shadow-color);
    background: var(--hover-color);
  }

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  &--outline {
    background: transparent;
    color: var(--background-color);
    border-color: var(--background-color);

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      color: var(--color);
      background: var(--background-color);
    }
  }

  &--loading {
    position: relative;

    span {
      pointer-events: none;
      opacity: 0;
    }

    .loader {
      margin: 0 -0.2rem;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      span {
        --animation-duration: 1.5s;
        --animation-offset: calc(var(--animation-duration) / 3);

        width: 0.5rem;
        height: 0.5rem;
        background: currentColor;
        border-radius: 9999px;
        margin: 0 0.2rem;

        &:nth-of-type(1) {
          animation: fadeInOut var(--animation-duration) calc(var(--animation-offset) * -3) linear infinite;
        }

        &:nth-of-type(2) {
          animation: fadeInOut var(--animation-duration) calc(var(--animation-offset) * -2) linear infinite;
        }

        &:nth-of-type(3) {
          animation: fadeInOut var(--animation-duration) calc(var(--animation-offset) * -1) linear infinite;
        }
      }
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
