@import '@/styles/base/container';

.root {
  font-family: theme('fontFamily.grotesk');

  .links {
    font-size: theme('fontSize.sm');
    background: theme('colors.yellow.base');
    padding-top: 3rem;
    padding-bottom: 3rem;

    @screen sm {
      display: flex;
      justify-content: space-between;
    }

    .logo {
      display: block;
      max-width: 240px;
      margin: auto;
      width: 100%;

      @screen sm {
        margin: 0;
      }

      @screen lg {
        max-width: 200px;
      }

      @screen xl {
        max-width: 320px;
      }
    }

    .link-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @screen sm {
        max-width: theme('maxWidth.lg');
        margin-left: 2rem;
      }

      @screen lg {
        max-width: none;
        margin-left: 2.5rem;
      }

      .app-links {
        font-family: theme('fontFamily.grotesk');
        display: grid;
        flex-grow: 1;
        gap: 0rem 1rem;

        @screen lg {
          gap: 3rem 1rem;
          grid-template-columns: repeat(4, 1fr);
        }

        > li {
          height: fit-content;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);

          @screen lg {
            border-bottom: none;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 1.125rem var(--container-space-x);
            color: theme('colors.white');
            text-transform: uppercase;
            text-align: left;
            font-size: theme('fontSize.xs');
            font-weight: theme('fontWeight.semibold');
            letter-spacing: theme('letterSpacing.widest');
            background: transparent;
            transition: background 0.2s ease;

            @screen lg {
              pointer-events: none;
              padding: 0;
            }

            &:hover,
            &:focus {
              background: theme('colors.yellow.highlight');
            }

            div {
              width: 1.25rem;
              height: 1.25rem;

              @screen lg {
                display: none;
              }

              svg {
                color: theme('colors.gray.500');

                &:first-child {
                  display: none;
                }

                &:last-child {
                  display: block;
                }
              }
            }

            &[aria-expanded='false'] {
              svg {
                color: theme('colors.white');

                &:first-child {
                  display: block;
                }

                &:last-child {
                  display: none;
                }
              }

              + [role='region'] {
                display: none;
              }
            }
          }

          [role='region'] {
            padding: 0 var(--container-space-x) 1rem var(--container-space-x);
            display: grid;
            gap: 0.75rem;

            @screen lg {
              padding: 0;
              margin-top: 1.5rem;
            }

            a:hover,
            a:focus {
              color: theme('colors.white');
              text-decoration: underline;
            }
          }
        }
      }

      .contact-links {
        li {
          display: flex;

          span {
            margin-right: 0.75rem;
          }
        }
      }

      .social-links {
        margin-top: 2.5rem;
        overflow: hidden;

        @screen sm {
          width: 100%;
        }

        &__main {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: -0.375rem;

          @screen sm {
            justify-content: end;
          }

          > li {
            margin: 0.375rem;

            a {
              display: block;
              width: 2rem;
              height: 2rem;
              background: theme('colors.white');
              border-radius: theme('borderRadius.full');
              color: theme('colors.yellow.base');
              position: relative;

              svg {
                display: block;
                width: 1.25rem;
                height: 1.25rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }
  }

  .terms-bar {
    @extend .container;
    color: theme('colors.white');
    background: theme('colors.gray.500');
    font-size: theme('fontSize.xs');
    padding-top: 2rem;
    padding-bottom: 2rem;

    @screen sm {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    &__main {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: -0.375rem -1rem;

      @screen sm {
        justify-content: space-between;
      }

      > * {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0.375rem 1rem;

        @screen sm {
          width: auto;
        }
      }
    }

    .text {
    }

    .terms {
      display: flex;

      li {
        a:hover,
        a:focus {
          text-decoration: underline;
        }

        &:not(:last-child)::after {
          content: '|';
          margin: 0 1rem;
        }
      }
    }
  }
}
