@import '@/styles/base/container';

.root {
  --menubar-height: 3rem;

  @screen md {
    --menubar-height: 5rem;
  }
}

.root {
  @extend .container;
  background: theme('colors.yellow.base');
  box-shadow: theme('boxShadow.md');
  position: sticky;
  top: 0;
  z-index: 999;

  .menubar {
    position: relative;
    height: var(--menubar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: theme('colors.white');

    &::after {
      content: '';
      position: absolute;
      left: calc(var(--container-space-x) * -1);
      bottom: 0;
      width: calc(100% + 2 * var(--container-space-x));
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
    }

    .logo {
      height: 100%;

      svg {
        height: 100%;
      }
    }

    .menu-items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        stroke-width: 2px;
      }

      > *:not(:first-child) {
        display: flex;
        align-items: center;
        margin-left: 1.5rem;

        @screen lg {
          margin-left: 2rem;
        }
      }

      .hamburger-button {
        svg {
          &:first-child {
            display: block;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  .dropdown {
    @extend .container;
    background: theme('colors.yellow.base');
    display: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: absolute;
    top: var(--menubar-height);
    left: 0;

    [role='tree'] {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 1rem;
      margin: 0 calc(var(--container-space-x) * -1) 2.5rem calc(var(--container-space-x) * -1);

      @screen md {
        margin: 0 0 0 calc(var(--container-space-x) / 2 * -1);
        max-width: theme('maxWidth.sm');
      }

      @screen lg {
        max-width: none;
        margin: 0 0 2.5rem 0;
        font-size: 14px;
      }

      @screen xl {
        font-size: 16px;
      }

      > * {
        grid-column: 1 / -1;

        @screen lg {
          grid-column: auto;
        }
      }

      > li[role='none'] {
        > a[role='treeitem'] {
          color: theme('colors.white');
          text-transform: uppercase;
        }
      }

      li[role='none'] {
        [role='group'] {
          margin: 0.25rem var(--container-space-x) 0.625rem calc(var(--container-space-x) + 1.5rem + 1rem);

          @screen md {
            margin: 0.25rem var(--container-space-x) 0.625rem calc(var(--container-space-x) / 2 + 1.5rem + 1rem);
          }

          @screen lg {
            margin: 1.5rem 0 0 0;
          }

          li a {
            padding: 0.375rem 0;
          }

          li {
            &:first-of-type a {
              padding-top: 0;
            }

            &:last-of-type a {
              padding-bottom: 0;
            }
          }
        }

        a[role='treeitem'] {
          display: flex;
          justify-content: space-between;
          padding: 0.625rem var(--container-space-x);

          @screen md {
            padding: 0.625rem calc(var(--container-space-x) / 2);
          }

          @screen lg {
            display: inline-block;
            padding: 0;
          }

          &.active {
            color: white;
          }

          &[aria-owns] > div {
            display: flex;
            align-items: center;

            svg {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 1rem;
              flex-shrink: 0;

              @screen lg {
                margin-right: 0.5rem;
              }

              @screen xl {
                margin-right: 1rem;
              }
            }
          }

          .chevron {
            width: 1.5rem;
            height: 1.5rem;
            transform: rotate(0deg);
            transition: all 0.2s ease;

            @screen lg {
              display: none;
            }
          }

          &[aria-expanded='false'] {
            + [role='group'] {
              display: none;
            }
          }

          &[aria-expanded='true'] {
            .chevron {
              color: theme('colors.black');
              transform: rotate(180deg);
            }

            + [role='group'] {
              display: block;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-self: start;
      margin: -0.5rem;

      @screen md {
        flex-direction: column-reverse;
      }

      @screen lg {
        flex-direction: row;
        justify-content: flex-end;
      }

      > * {
        margin: 0.5rem;

        span {
          display: block;
          text-align: center;
        }
      }
    }
  }

  &[data-is-open='true'] {
    .dropdown {
      display: grid;
      column-gap: 2rem;
      box-shadow: 10px 50px 20px rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid white;

      @screen md {
        grid-template-columns: 1fr auto;
      }

      @screen lg {
        grid-template-columns: 1fr;
      }
    }

    .menu-items {
      .hamburger-button {
        svg {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }
        }
      }
    }
  }
}
