.root {
  display: flex;
  justify-content: space-between;

  a {
    color: theme('colors.yellow.base');
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: theme('fontSize.xs');
    font-weight: theme('fontWeight.semibold');
    letter-spacing: theme('letterSpacing.widest');

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      stroke-width: 2px;
    }

    &:first-of-type svg {
      margin-right: 0.75rem;
    }

    &:last-of-type svg {
      margin-left: 0.75rem;
    }
  }
}
