.root {
  h1 {
    font-family: theme('fontFamily.sangbleu');
    font-size: theme('fontSize.4xl');
    margin-bottom: 1rem;
  }

  &__introheader {
      max-width: theme('maxWidth.3xl');

      p {
        margin-bottom: 1rem;
      }

      ul {
        list-style-type: disc;
        margin-left: 1.1rem;
      }

      a {
        text-decoration: none;
        color: theme('colors.yellow.highlight');

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
  }

  &__buttons {
    margin: -0.5rem;

    > * {
      margin: 0.5rem;
    }
  }
}
