.container {
  width: 100%;
  max-width: calc(1636px + var(--container-space-x) * 2);
  padding-left: var(--container-space-x);
  padding-right: var(--container-space-x);
  margin-left: auto;
  margin-right: auto;

  &--grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    @screen sm {
      column-gap: 2rem;
    }
  }
}
