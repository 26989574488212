@import '@/styles/base/container';

.root {
  min-height: 100vh;
  --spacing-vertical: 3rem;
  --introheader-height: 0;
  --introheader-spacing-top: 2rem;

  @screen lg {
    --spacing-vertical: 4rem;
  }

  @screen xl {
    --spacing-vertical: 5rem;
  }
}

.root {
  @extend .container--grid;
  gap: 0;

  .main {
    @extend .container;
    overflow: visible;
    padding-top: var(--spacing-vertical);
    padding-bottom: var(--spacing-vertical);
    grid-column: 1 / -1;
    grid-row-start: 1;
    display: flex;
    flex-direction: column;

    @screen md {
      grid-column: span 7 / span 7;
    }

    @screen xl {
      grid-column: span 8 / span 8;
    }

    .intro-header {
      margin-bottom: var(--introheader-spacing-top);

      &--with-media {
        @extend .container;
        position: relative;
        width: 100vw;
        margin-left: calc(var(--container-space-x) * -1);
        display: grid;
        gap: 2rem;

        @screen md {
          grid-template-columns: minmax(50%, 1fr) minmax(auto, 550px);
        }
      }

      h1 {
        word-break: break-word;
        hyphens: auto;
      }
    }

    &--with-nav {
      padding-bottom: 0;
    }

    .prev-next {
      margin-top: auto;

      > div {
        margin-top: 4rem;
        background: theme('colors.beige.light');
        margin-left: calc(var(--container-space-x) * -1);
        margin-right: calc(var(--container-space-x) * -1);
        padding: var(--spacing-vertical) var(--container-space-x) var(--spacing-vertical) var(--container-space-x);

        @screen md {
          margin-top: 6rem;
        }

        @screen lg {
          margin-top: 7.5rem;
          padding: 2rem calc(var(--container-space-x)) 7.5rem calc(var(--container-space-x));
        }
      }
    }
  }

  .aside {
    @extend .container;
    padding-top: 0;
    padding-top: var(--spacing-vertical);
    padding-bottom: var(--spacing-vertical);
    grid-column: 1 / -1;
    grid-row-start: 2;

    @screen md {
      padding-top: calc(var(--spacing-vertical) + calc(var(--introheader-height) * 1px));
      grid-column: span 5 / span 5;
      grid-row-start: 1;

      &[data-has-media='true'] {
        padding-top: calc(
          var(--spacing-vertical) + calc(var(--introheader-height) * 1px) + var(--introheader-spacing-top)
        );
      }
    }

    @screen xl {
      grid-column: span 4 / span 4;
      padding-left: 3rem;
    }
  }

  .section {
    @extend .container;
    grid-column: 1 / -1;
    padding-top: var(--spacing-vertical);
    padding-bottom: var(--spacing-vertical);
  }

  &--full-width {
    .main {
      grid-column: 1 / -1;
    }
  }

  &--aside-top {
    .main {
      grid-row-start: 2;

      @screen md {
        grid-row-start: 1;
      }
    }

    .aside {
      grid-row-start: 1;
    }
  }
}
